(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/balance-bar/views/ao-brand.js') >= 0) return;  svs.modules.push('/components/lb-ui/balance-bar/views/ao-brand.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.lb_ui=_svs.lb_ui||{};
_svs.lb_ui.balance_bar=_svs.lb_ui.balance_bar||{};
_svs.lb_ui.balance_bar.templates=_svs.lb_ui.balance_bar.templates||{};
svs.lb_ui.balance_bar.templates.ao_brand = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"balance-bar-ao-brand balance-bar-ao-brand-sport balance-bar-ao-sharp-divider\">\n  <div class=\"balance-bar-ao-brand-content\">\n    <img class=\"balance-bar-ao-brand-divider\" alt=\"\"\n      src=\""
    + container.escapeExpression((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/images/components/ao-logotype-soc/ao-soc-cut-divided.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":86}}}))
    + "\">\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-lb_ui-balance_bar-ao_brand'] = svs.lb_ui.balance_bar.templates.ao_brand;
})(svs, Handlebars);


 })(window);